var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('viewcard--c',{attrs:{"title":_vm.$route.params.id > 0 ? 'Atualizar Turno' : 'Cadastrar Turno',"btsave":_vm.$route.params.id > 0 ? _vm.btedit : _vm.btcreate,"btdelete":null,"btback":{},"busy":_vm.loading},on:{"clicked-save":_vm.validationForm,"clicked-delete":function($event){{
    }}}},[_c('hr',{staticClass:"p-0 m-0 mb-1"}),_c('validation-observer',{ref:"formatRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nome *"}},[_c('validation-provider',{attrs:{"name":"Nome do Turno","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Madrugada, Manhã, Tarde ou Noite"},model:{value:(_vm.record.name),callback:function ($$v) {_vm.$set(_vm.record, "name", $$v)},expression:"record.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Início *"}},[_c('validation-provider',{attrs:{"name":"Início do Turno","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Início","config":{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: 'H:i',
                  time_24hr: true,
                }},model:{value:(_vm.record.start),callback:function ($$v) {_vm.$set(_vm.record, "start", $$v)},expression:"record.start"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Término *"}},[_c('validation-provider',{attrs:{"name":"Término do Turno","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Término","config":{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: 'H:i',
                  time_24hr: true,
                }},model:{value:(_vm.record.end),callback:function ($$v) {_vm.$set(_vm.record, "end", $$v)},expression:"record.end"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }