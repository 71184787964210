<template>
  <viewcard--c
    :title="$route.params.id > 0 ? 'Atualizar Turno' : 'Cadastrar Turno'"
    :btsave="$route.params.id > 0 ? btedit : btcreate"
    :btdelete="null"
    :btback="{}"
    :busy="loading"
    @clicked-save="validationForm"
    @clicked-delete="
      {
      }
    "
  >
    <hr class="p-0 m-0 mb-1" />
    <validation-observer ref="formatRules">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="6">
            <b-form-group label="Nome *">
              <validation-provider
                #default="{ errors }"
                name="Nome do Turno"
                rules="required"
              >
                <b-form-input
                  v-model="record.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Madrugada, Manhã, Tarde ou Noite"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Início *">
              <validation-provider
                #default="{ errors }"
                name="Início do Turno"
                rules="required"
              >
                <flat-pickr
                  v-model="record.start"
                  placeholder="Início"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                    time_24hr: true,
                  }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Término *">
              <validation-provider
                #default="{ errors }"
                name="Término do Turno"
                rules="required"
              >
                <flat-pickr
                  v-model="record.end"
                  placeholder="Término"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                    time_24hr: true,
                  }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </viewcard--c>
</template>
<script>
import _workShiftService from "@/services/audiovisual/work-shift-service";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import { required } from "@validations";
import { BFormFile } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormFile,
  },
  data() {
    return {
      btedit: { permission: `permission.audiovisual.demands.workshift.edit` },
      btcreate: {
        permission: `permission.audiovisual.demands.workshift.create`,
      },
      btdelete: {
        permission: `permission.audiovisual.demands.workshift.delete`,
      },
      loading: false,
      record: {
        id: 0,
        name: "",
        active: true,
      },
    };
  },
  created() {
    localize("pt_BR", pt_br);
    this.getRecord();
  },
  methods: {
    validationForm() {
      this.$refs.formatRules.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    getRecord() {
      if (this.$route.params.id > 0) {
        this.loading = true;
        _workShiftService
          .find(this.$route.params.id)
          .then((res) => {
            this.record = res.content;
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.loading = false));
      }
    },
    save() {
      const payload = { data: { ...this.record } };

      //promisse
      const _createOrUpdate =
        this.record.id <= 0
          ? _workShiftService.create(payload)
          : _workShiftService.update(payload);

      this.loading = true;
      _createOrUpdate
        .then(() => {
          this.$utils.toast("Notificação", "Registro salvo com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>